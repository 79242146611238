// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/background1.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*[data-v-b93016de]{margin:0;padding:0}.view[data-v-b93016de]{transition:transform 1s ease;padding:30px;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);background-color:hsla(0,0%,100%,.3);width:1480px;height:1000px;display:flex}.view[data-v-b93016de],.view .menu[data-v-b93016de]{border-radius:20px;transition:all 1s}.view .menu[data-v-b93016de]{width:300px;height:100%;position:fixed;left:0;top:0}.view .content[data-v-b93016de]{flex:1;height:100%;background-color:hsla(0,0%,100%,.1);border-radius:10px;padding-left:270px}.view h3[data-v-b93016de]{font-weight:900}.view h3 button[data-v-b93016de]{border:none;margin-bottom:20px;background-color:hsla(0,0%,100%,0)}@media screen and (max-width:1920px){.view[data-v-b93016de]{width:1280px;height:800px;padding:20px}.view .menu[data-v-b93016de]{width:200px;height:100%;transition:all 1s}.view .content[data-v-b93016de]{padding-left:180px}.view h3[data-v-b93016de]{font-size:14px}}@media screen and (max-width:1480px){.view[data-v-b93016de]{width:1080px;height:650px}.view h3[data-v-b93016de]{font-size:14px}}@media screen and (max-width:1280px){.view[data-v-b93016de]{width:1080px;height:600px}.view h3[data-v-b93016de]{font-size:14px}}.view[data-v-b93016de]:hover{transform:translate(-50%,-51%);transition:transform 1s ease}.box[data-v-b93016de]{top:0;bottom:0;left:0;right:0;position:fixed;width:100%;height:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-attachment:fixed;background-size:cover;z-index:-1}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
