<template>
    <body id="poster">
        <div class="login">
            <el-form class="formlist" :label-position="labelPosition" label-width="0px" :model="formLabelAlign">
                <h3>
                    ANNI后台管理系统
                    <el-button @click="tologin">点我登录</el-button>
                </h3>
                <el-form-item label="">
                    <el-input type="text" v-model="formLabelAlign.Loginusername" placeholder="用户名(3-8位)"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input type="text" v-model="formLabelAlign.Loginname" placeholder="账号(3-8位)"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input type="password" v-model="formLabelAlign.Loginpassword" placeholder="密码(8-16位)"></el-input>
                </el-form-item>
            </el-form>
            <el-button type="primary" style="width:100%;background:#505458;" @click="Login">注册</el-button>
        </div>
        <div class="box"></div>
    </body>
</template>
  
<script>

export default {
    name: 'Login',
    components: {

    },
    data() {
        return {
            labelPosition: 'right',
            formLabelAlign: {
                Loginusername: '',
                Loginname: '',
                Loginpassword: ''
            }
        };
    },
    created() {

    },
    methods: {
        Login() {
            console.log('submit!');
            if (this.formLabelAlign.Loginusername == '') {
                uni.showToast({
                    title: '用户名不为空',
                    icon: "error"
                })
            } else if (this.formLabelAlign.Loginname == '') {
                uni.showToast({
                    title: '账号不为空',
                    icon: "error"
                })
            } else if (this.formLabelAlign.Loginpassword.length < 8 || this.formLabelAlign.Loginpassword.length > 15) {
                uni.showToast({
                    title: '密码长度不合格',
                    icon: "error"
                })
            } else {
                this.axios.get(`https://anni.5ymdr.com/php/me/register.php?user=${this.formLabelAlign.Loginname}&password=${this.formLabelAlign.Loginpassword}&username=${this.formLabelAlign.Loginusername}`).then((resp) => {
                    console.log(resp)
                    let res = resp.data
                    if (res.code == 200) {
                        this.$message({
                            message: '注册成功,请登录账号',
                            type: 'success'
                        });
                        localStorage.setItem("token", res.data)
                        this.$router.push({ path: '/' })
                    } else if (res.code == 201) {
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        tologin() {
            this.$router.push({ path: '/' })
        }
    }
}
</script>
<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

.login {
    transition: all 1s;
    border-radius: 20px;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    width: 450px;
    height: 300px;

    h3 {
        font-weight: 900;

        button {
            border: none;
            margin-bottom: 20px;
            background-color: rgba(255, 255, 255, 0);
        }
    }
}

.login:hover {
    transform: translate(-50%, -52%);
    transition: transform 1s ease;
}

@media screen and (max-width:700px) {
    .login {
        width: 300px;
        height: 350px;

        h3 {
            font-size: 14px;
        }
    }
}

.box {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url('../../public/background2.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    z-index: -1;
}
</style>
  