// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*[data-v-26f72668]{margin:0;padding:0}.login[data-v-26f72668]{transition:transform 1s ease;border-radius:20px;padding:30px;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);background-color:hsla(0,0%,100%,.7);width:400px;height:250px}.login h3[data-v-26f72668]{font-weight:900}.login h3 button[data-v-26f72668]{border:none;margin-bottom:20px;background-color:hsla(0,0%,100%,0)}.login[data-v-26f72668]:hover{transform:translate(-50%,-52%);transition:transform 1s ease}@media screen and (max-width:700px){.login[data-v-26f72668]{width:300px;height:250px}.login h3[data-v-26f72668]{font-size:14px}}.box[data-v-26f72668]{top:0;bottom:0;left:0;right:0;position:fixed;width:100%;height:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-attachment:fixed;background-size:cover;z-index:-1}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
