<template>
    <div class="contents" v-if="this.SwiperList != ''">
        <div class="Menu" v-if="level == 1">
            <el-button type="primary" @click="Add">添加轮播图</el-button>
        </div>
        <el-table :data="SwiperList" class="table" border stripe>
            <el-table-column prop="id" label="ID" sortable width="100" align="center"></el-table-column>
            <el-table-column label="图标" align="center">
                <img :src="url + SwiperList[scope.$index].imageurl" alt="" slot-scope="scope">
            </el-table-column>
            <el-table-column label="操作" align="center" v-if="level == 1">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="轮播图详情" :visible.sync="dialogFormVisible" :append-to-body=true>
            <el-form :model="form">
                <el-form-item label="ID" :label-width="formLabelWidth">
                    <el-input v-model="form.id" disabled></el-input>
                </el-form-item>

                <el-form-item label="图标" :label-width="formLabelWidth">
                    <img v-select-file="upload" :src="url + form.imageurl" alt="" style="width:100px;height:100px;">
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="updata">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="添加轮播图" :visible.sync="dialogFormVisible1" :append-to-body=true>
            <el-form :model="form">
                <el-button type="primary" icon="el-icon-upload2" v-select-file="addupload"
                    v-if="SwiperFrom.imageurl == undefined">上传图片</el-button>
                <el-form-item label="图片" :label-width="formLabelWidth" v-if="SwiperFrom.imageurl != undefined">
                    <img v-select-file="addupload" :src="url + SwiperFrom.imageurl" style="width:100px;height:100px;">
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="updataadd">添 加</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ImageUpload } from '@/utils/common.js'
export default {
    created() {
        this.GetSwiperList()
    },
    data() {
        return {
            path: '',
            title: '',
            token: '',
            SwiperList: [],
            dialogFormVisible: false,
            dialogFormVisible1: false,
            form: {},
            SwiperFrom: {},
            formLabelWidth: '200',
            level: 0,
            url: 'https://anni.5ymdr.com/'
        };
    },
    methods: {
        async upload(event) {
            let arr = Array.from(event.target.files).map(async (file) => {
                try {
                    let result = await ImageUpload(file)
                    // let url = "http://101.200.128.203:9712" + result.data
                    let url = result.data
                    return url
                } catch (err) {
                    console.error(err)
                    return null
                }
            })
            let result = []
            for (let index = 0; index < arr.length; index++) {
                let val = await arr[index]
                if (val) {
                    result.push(val)
                }
            }
            this.form.imageurl = result[0].data.url
            event.target.type = 'text';
            event.target.value = '';
            event.target.type = 'file';
        },
        async addupload(event) {
            let arr = Array.from(event.target.files).map(async (file) => {
                try {
                    let result = await ImageUpload(file)
                    let url = result.data
                    return url
                } catch (err) {
                    console.error(err)
                    return null
                }
            })
            let result = []
            for (let index = 0; index < arr.length; index++) {
                let val = await arr[index]
                if (val) {
                    result.push(val)
                }
            }
            this.$set(this.SwiperFrom, 'imageurl', result[0].data.url)
            console.log(this.SwiperFrom.imageurl);
            event.target.type = 'text';
            event.target.value = '';
            event.target.type = 'file';
        },
        //更新数据
        updata() {
            this.dialogFormVisible = false
            this.axios.get(`https://anni.5ymdr.com/php/admin/LockSwiper.php?ids=${this.form.id}&url=${this.form.imageurl}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                }
            })
        },
        updataadd() {
            this.dialogFormVisible1 = false
            this.axios.get(`https://anni.5ymdr.com/php/admin/Add.php?&url=${this.SwiperFrom.imageurl}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$router.go(0);
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                }
            })
        },
        //打开编辑
        handleEdit(index, row) {
            console.log(index, row);
            this.dialogFormVisible = true
            this.form = row
        },
        //点击删除
        handleDelete(index, row) {
            // console.log(index, row);
            this.$confirm('确定要删除吗？？')
                .then(_ => {
                    this.$confirm('删除后将无法恢复')
                        .then(_ => {
                            this.deletes(row)
                        })
                        .catch(_ => { this.cancel() });
                })
                .catch(_ => { this.cancel() });
        },
        cancel() {
            this.$message({
                message: '取消删除',
                type: 'warning'
            });
        },
        deletes(row) {
            // console.log('确认删除')
            this.axios.get(`https://anni.5ymdr.com/php/admin/DropSwiper.php?ids=${row.id}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.SwiperList = this.SwiperList.filter(item => { return item != row })
                } else if (res.code = 201) {
                    this.$message.error(res);
                } else if (resp.code = 403) {
                    this.$router.push({ path: '/' })
                }
            })
        },
        GetSwiperList() {
            //查询该用户是否为管理员
            this.level = localStorage.getItem("level")
            this.token = localStorage.getItem("token")
            this.axios.get(`https://anni.5ymdr.com/php/index/Swiper.php?token=` + this.token).then((resp) => {
                let res = resp.data
                console.log(res);
                if (res.code == 200) {
                    this.SwiperList = res.data
                } else if (res.code = 201) {
                    this.$message.error(res.msg);
                } else if (resp.code = 403) {
                    this.$router.push({ path: '/' })
                }
            })
        },
        Add(index, row) {
            console.log(index, row);
            this.dialogFormVisible1 = true
        },
    }
}
</script>

<style lang="less" scoped>
.Menu {
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 30px;
}

img {
    width: 170px;
    height: 100px;
    // border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .16);
    border: 3px solid #eee;
}

.level {
    width: 100px;
    margin: 0 auto;
    font-size: 75%;
    font-weight: 900;
    color: #fff;
    border-radius: 0.25em;
    padding: 8px 15px;
}

.orange {
    background-color: #f0ad4e;
}

.green {
    background-color: rgb(0, 172, 132);
}

.contents {
    max-height: 1000px;
    display: inline-block;
    width: 1210px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 1s;
}

@media screen and (max-width:1920px) {
    .contents {
        height: 800px;
        width: 1100px;
        transition: all 1s;
    }
}

@media screen and (max-width:1480px) {
    .contents {
        height: 650px;
        width: 900px;
        transition: all 1s;
    }
}

@media screen and (max-width:1280px) {
    .contents {
        height: 600px;
        transition: all 1s;
    }
}

.table {
    background-color: rgba(255, 255, 255, 0.1);
}
</style>