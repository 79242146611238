<template>
    <div class="contents" v-if="this.Billlist != ''">
        <el-table :data="Billlist" class="table" border stripe>
            <el-table-column prop="id" label="文章ID" width="100" align="center"></el-table-column>
            <!-- <el-table-column prop="Context" label="内容" align="center"> </el-table-column> -->
            <el-table-column prop="title" label="标题" align="center"></el-table-column>
            <el-table-column prop="type" label="类型" align="center"></el-table-column>
            <!-- <el-table-column prop="" label="预览图" align="center" slot-scope="scope">
                <img :src="Billlist[scope.$index].imageurl" alt="">
            </el-table-column> -->
            <el-table-column prop="readNum" label="阅读量" align="center"></el-table-column>
            <el-table-column prop="time" label="创建时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-form style="height:40px;">
                <el-form-item label="选择类型：" label-width="100px">
                    <el-select v-model="id" placeholder="请选择" @change="type" size="mini">
                        <el-option v-for="item in options" :key="item.id" :label="item.type" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[10, 20, 40, 60, 80, 100]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="this.total">
            </el-pagination>
        </div>
        <el-dialog title="文章详情" :visible.sync="dialogFormVisible" :append-to-body=true>
            <el-form :model="form">
                <el-form-item label="标题" :label-width="formLabelWidth">
                    <el-input v-model="form.title" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型" :label-width="formLabelWidth">
                    <el-select v-model="form.tid" placeholder="选择分类">
                        <el-option :label="item.type" :value="item.id" :key="item.tid" v-for="item in options"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="阅读量" :label-width="formLabelWidth">
                    <el-input v-model="form.readNum" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="创建时间" :label-width="formLabelWidth">
                    <el-input v-model="form.time" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="详细内容" :label-width="formLabelWidth">
                    <el-input v-model="form.context" autocomplete="off" type="textarea" autosize disabled></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="updata">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    created() {
        this.level = localStorage.getItem("level")
        this.token = localStorage.getItem("token")
        this.Gettype()
    },
    data() {
        return {
            path: '',
            title: '',
            token: '',
            Billlist: [],
            dialogFormVisible: false,
            form: {},
            formLabelWidth: '2000',
            level: '',
            currentPage: 1,
            total: 1,
            pageSize: 20,
            checked: false,
            checkList: [],
            DropRows: '',
            options: [],
            id: ''
        };
    },
    methods: {
        type() {
            console.log(this.id);
            this.GetBill()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.GetBill()
        },
        // 分页功能
        handleCurrentChange(val) {
            this.currentPage = val
            this.GetBill()
        },
        //更新数据
        updata() {
            this.dialogFormVisible = false
            console.log(this.form);
            this.axios.get(`https://anni.5ymdr.com/php/admin/Lockemotion.php?id=${this.form.id}&title=${this.form.title}&type=${this.form.type}&tid=${this.form.tid}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data.data
                if (res) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$router.go(0)
                }
            })
        },
        //打开编辑
        handleEdit(index, row) {
            // console.log(index, row);
            this.dialogFormVisible = true
            this.axios.get(`https://anni.5ymdr.com/php/admin/context.php?id=${row.id}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                this.form = row
                this.form.context = res.data.context
            })
        },
        //询问是否删除
        handleDelete(index, row) {
            this.$confirm('确定要删除此数据吗？？')
                .then(_ => {
                    this.deletes(row)
                })
                .catch(_ => { });
        },
        // 执行删除
        deletes(row) {
            console.log(row)
            this.axios.get(`https://anni.5ymdr.com/php/admin/Dropemotion.php?id=${row.id}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.Billlist = this.Billlist.filter(item => { return item != row })
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                }
            })
        },
        handleClose() {
            this.$confirm('确认关闭？')
                .then(_ => { })
                .catch(_ => { });
        },
        GetBill() {
            //查询该用户是否为管理员
            this.axios.get(`https://anni.5ymdr.com/php/admin/emotion.php?tid=${this.id}&page=${this.currentPage}&pageSize=${this.pageSize}&token=` + this.token).then((resp) => {
                let res = resp.data
                console.log(res);
                if (res.code == 200) {
                    this.Billlist = res.data.data
                    this.total = res.data.total
                    console.log(this.Billlist);
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                } else if (resp.code == 403) {
                    this.$router.push({ path: '/' })
                }
            })
        },
        Gettype() {
            this.axios.get(`https://anni.5ymdr.com/php/admin/type.php?&token=` + this.token).then((resp) => {
                let res = resp.data
                console.log(res);
                this.options = res.data
                this.id = res.data[0].id
                console.log(this.id);
                this.GetBill()
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-table::before {
    height: 0;
}

.contents {
    background-color: #fff;
}

.table {
    max-height: 965px;
    display: inline-block;
    width: 1210px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 1s;
}

@media screen and (max-width:1920px) {
    .table {
        height: 765px;
        width: 1100px;
        transition: all 1s;
    }
}

@media screen and (max-width:1480px) {
    .table {
        height: 615px;
        width: 900px;
        transition: all 1s;
    }
}

@media screen and (max-width:1280px) {
    .table {
        height: 565px;
        transition: all 1s;
    }
}

.block {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

img {
    width: 700px;
}
</style>