<template>
    <div>
        <div class="user">
            <img :src="url + this.user.imageurl" alt="" v-if="this.user != ''">
            <img src="" alt="" v-if="this.user == ''">
            <h3>{{ user.username }}</h3>
            <h3 v-if="this.user == ''">普通用户</h3>
            <div :class="user.level == 0 ? 'level orange' : 'level green'">
                {{ user.level == 1 ? '系统管理员' : 'VIP会员' }}
            </div>
            <div class="userid">用户ID： 00{{ user.id }}</div>
        </div>
        <el-menu :default-active="$route.path" @open="handleOpen" @close="handleClose"
            background-color="rgba(255, 255, 255, 0)" active-text-color="#179fff" router>
            <el-menu-item index="/Index">
                <i class="el-icon-edit-outline"></i>
                <span slot="title">个人中心</span>
            </el-menu-item>

            <el-menu-item index="/UserInfo">
                <i class="el-icon-user-solid"></i>
                <span slot="title">用户管理</span>
            </el-menu-item>

            <el-menu-item index="/Fx">
                <i class="el-icon-link"></i>
                <span slot="title">好物分享设置</span>
            </el-menu-item>

            <el-menu-item index="/Swiper">
                <i class="el-icon-chat-dot-square"></i>
                <span slot="title">轮播图管理</span>
            </el-menu-item>

            <el-menu-item index="/Article">
                <i class="el-icon-document"></i>
                <span slot="title">文章管理</span>
            </el-menu-item>

            <el-menu-item index="/" @click="exit">
                <i class="el-icon-circle-close"></i>
                <span slot="title">退出登录</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: '',
            user: [],
            url: "https://anni.5ymdr.com/"
        };
    },
    created() {
        this.getuers()
    },
    methods: {
        getuers() {
            this.token = localStorage.getItem("token")
            if (this.token == "" || this.token == undefined) {
                this.$message.error("未登录账号");
                this.$router.push({ path: '/' })
            }
            // console.log(localStorage.getItem("token"))
            this.axios.get(`https://anni.5ymdr.com/php/me/getusers.php?token=` + this.token).then((resp) => {
                let res = resp.data
                if (res.code == 200) {
                    this.user = res.data
                    localStorage.setItem("level", this.user.level)
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                } else if (resp.code == 403) {
                    this.$router.push({ path: '/' })
                }
            })
        },
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        exit() {
            this.$message({
                message: '您已退出登录',
                type: 'warning'
            });
            localStorage.removeItem("token")
        }
    }
}
</script>

<style lang="less" scoped>
.user {
    padding: 30px 20px;

    img {
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, .16);
        border: 8px solid #eee;
        transition: all 1s;
        width: 100px;
        height: 100px;
    }

    @media screen and (max-width:1800px) {
        img {
            width: 85px;
            height: 85px;
            transition: all 1s;
        }
    }

    @media screen and (max-width:1480px) {
        img {
            width: 75px;
            height: 75px;
            transition: all 1s;
        }
    }

    @media screen and (max-width:1280px) {
        img {
            display: none;
        }
    }

    h3 {
        margin: 20px 0;
        // font-weight: 900;
        font-size: 24px;
    }

    .level {
        width: 70px;
        margin: 0 auto;
        font-size: 75%;
        font-weight: 900;
        color: #fff;
        border-radius: 0.25em;
        padding: 10px 15px;
    }

    .orange {
        background-color: #f0ad4e;
    }

    .green {
        background-color: rgb(0, 172, 132);
    }

    .userid {
        width: 150px;
        margin: 10px auto;
        font-weight: 700;
    }
}
</style>