import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Index from '../views/Index.vue'
import Me from '../views/Content/Me.vue'
import UserInfo from '../views/Content/UserInfo.vue'
import Swiper from '../views/Content/Swiper.vue'
import Fx from '../views/Content/Fx.vue'
import Article from '../views/Content/Article.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  }, {
    path: '/Register',
    name: 'Register',
    component: Register
  }, {
    path: '/Index',
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/Index',
        name: 'Me',
        component: Me
      },
      {
        path: '/UserInfo',
        name: 'UserInfo',
        component: UserInfo
      },
      {
        path: '/Swiper',
        name: 'Swiper',
        component: Swiper
      },
      {
        path: '/Fx',
        name: 'Fx',
        component: Fx
      },
      {
        path: '/Article',
        name: 'Article',
        component: Article
      }

    ]
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
