import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=3714c5de&scoped=true&"
import script from "./Article.vue?vue&type=script&lang=js&"
export * from "./Article.vue?vue&type=script&lang=js&"
import style0 from "./Article.vue?vue&type=style&index=0&id=3714c5de&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3714c5de",
  null
  
)

export default component.exports